@import "~bootstrap/scss/bootstrap";
@import "./assets/styles/navigationBar.scss";
@import "./assets//styles//buttons.scss";
@import "./assets//styles//formControl.scss";
@import "./assets/styles/layouts/createInfringementPage.scss";
@import "./assets/styles/layouts/searchNoticePage.scss";
@import "./assets/styles/footer.scss";
@import "./assets/styles/tabs.scss";
@import "./assets/styles/components/searchSR.scss";
@import "./assets/styles/modal.scss";
@import "./assets//styles/table.scss";
@import "./assets/styles/components/maintenance.scss";
@import "./assets/styles/miscellaneous.scss";

@font-face {
  font-family: 'GuardianSans';
  font-weight: 700;
  src: local('GuardianSans-Semibold-Web'), url(./assets/fonts/GuardianSans-Semibold-Web.woff) format('woff');
}

@font-face {
  font-family: 'GuardianSans';
  font-weight: 500;
  src: local('GuardianSans-Regular-Web'), url(./assets/fonts/GuardianSans-Regular-Web.woff) format('woff');
}

@font-face {
  font-family: 'GuardianSans';
  font-weight: 300;
  src: local('GuardianSans-Light-Web'), url(./assets/fonts/GuardianSans-Light-Web.woff) format('woff');
}

.section-header {
  display: flex;
  margin: 2rem 0;
  font-weight: 700 !important;
}

body {
  font-family: 'GuardianSans' !important;
}